<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.maintenanceItemsConfiguration')"
  >
    <div id="outer-title">
      {{ $t("i18n.maintenanceItemsConfiguration") }}
    </div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "MaintenanceItemsConfigitemCompany",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "name",
          label: "companyName",
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "address",
          label: "companyAddress",
          align: "left",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "maintenanceConfiguration",
            icon: "el-icon-position",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/maintenance/maintenanceItemsConfigselect",
                query: { id: row.id },
              });
            },
          },
          {
            id: "2",
            label: "ModeJoylive",
            icon: "el-icon-position",
            show: proxy.$defined.btnPermission("基础模板"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              setAllItemMode(row.id);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      params.companyName = params.name;
      params.companyAddress = params.address;
      const { data } = await proxy.$api.system.companyPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.elevatorId = val.number.id;
      val.number = val.number.number;
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      val.id
        ? await proxy.$api.monitor.editCollector(val.id, val)
        : await proxy.$api.monitor.addCollector(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row, pages) => {
      proxy.$api.monitor.deleteCollector(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const remoteEleMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "number";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state.columns[index].data = data.data;
              state.columns[index].loading = false;
            });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    const setAllItemMode = (id) => {
      // 基础模板
      proxy.$defined.confirm(() => {
        proxy.$api.maintenance.setAllItemMode({ companyId: id }).then(() => {
          proxy.$defined.tip(t("i18n.success"), "success");
        });
      }, t("i18n.useMode"));
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      remoteEleMethod,
      getEditData,
      setAllItemMode,
    };
  },
};
</script>
